import * as React from 'react'
import Image from 'next/image'
import { Container } from '@/components/Container'

export function Hero() {
  const [isLoading, setIsLoading] = React.useState(false)

  // We handle form submission with the form element, this is just to change the button styles
  // https://dev.to/nikolasbarwicki/redirect-to-a-page-from-nextjs-api-route-1nig
  const handleSubmit = async (event) => {
    // event.preventDefault()
    setIsLoading(true)
  }

  return (
    <div className="overflow-hidden bg-paper py-8 sm:py-32 lg:pb-32 xl:pb-36">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
          <div className="relative z-10 mx-auto max-w-2xl lg:col-span-5 lg:max-w-none lg:pt-6 xl:col-span-5">
            <h1 className="font-shift text-5xl text-primary">
              Enrich your life through writing
            </h1>
            <p className="mt-6 font-figtree text-lg text-primary">
              Explore the works of Shakespeare, Sappho and others with engaging
              audio and guided writing prompts. Tell your story with creative
              writing and journaling exercises. Start creating with Writana.
            </p>

            <div>
              <form
                action="/api/waitlist-signup"
                method="POST"
                onSubmit={handleSubmit}
                className="mt-6 flex max-w-md flex-col gap-x-4 sm:flex-row"
              >
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="h-12 min-w-0 flex-auto rounded-md border-0 bg-white/20 px-3.5 py-2 text-primary shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primaryText sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className={`${
                    isLoading ? 'cursor-not-allowed opacity-50' : ''
                  } mt-10 flex-none rounded-full bg-primaryText py-2.5 px-7 font-figtree text-lg text-white sm:mt-0`}
                  disabled={isLoading}
                >
                  Join app waitlist
                </button>
              </form>
            </div>
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-7 lg:row-span-2 lg:-mt-20 xl:col-span-7">
            {/* TODO: swap for optimized, smaller image */}
            <div className="flex justify-center">
              <Image
                className="align-self-center"
                src={'/placeholder-hero-2.jpg'}
                sizes="(max-width: 768px) 100vw,
              (max-width: 1100px) 100vw,
              70vw"
                width={700}
                height={700}
                alt="magical imagination image"
                // https://nextjs.org/docs/api-reference/next/image#priority
                priority={true}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
