import Link from 'next/link'
import { Container } from '@/components/Container'
import Image from 'next/image'

export function Header() {
  return (
    <header className="bg-paper">
      <nav>
        <Container className="relative flex justify-between py-8">
          <div className="relative flex items-center gap-16">
            <Link href="/" aria-label="Home">
              <Image
                height={49}
                width={200}
                src="/horizontal-logo.png"
                alt="horizontal Writana brand logo"
              />
            </Link>
          </div>
        </Container>
      </nav>
    </header>
  )
}
